.thumbnail-scrollview {
    margin-left: auto;
}

.thumbnail-box {
    border-width: 1px;
    border-color: #ccc;
    border-left-style: solid;
    border-right-style: solid;
    padding-top: 0.5rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    background-color: #d6d6ff;
}

.thumbnail-box.single {
    padding-bottom: 0.5rem;
    margin-bottom: 1rem;
    border-top-style: solid;
    border-bottom-style: solid;
    border-radius: 10px;
}

.thumbnail-box.top {
    padding-bottom: 0.5rem;
    border-top-style: solid;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.thumbnail-box.middle {
    padding-bottom: 0.5rem;
}

.thumbnail-box.bottom {
    padding-bottom: 0.5rem;
    margin-bottom: 1rem;
    border-bottom-style: solid;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.thumbnail {
    width: 12rem;
    height: 18rem;
    margin: 10px 0;
}

.preview-scrollview {
    width: 100%;
    min-width: 600px;
}

.preview {
    width: 72rem;
    height: 108rem;
    margin: 10px;
}

.main {
    border: 1px solid #ccc;
    border-radius: 10px;
    padding: 20px;
    flex: 1; /* let the main container fill the remaining space */
    height: 100px;
}

.input {
    background-color: #fff;
}

.button-box {
    margin-top: auto;
}

/* let the root container fill the screen */
body > #root > div {
    height: 100vh;
}